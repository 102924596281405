import React from 'react'
import photo from '../portaits/portrait.jpg'
export default function AboutMe() {
	return (
		<div className='AboutMe'>
			<h1>Few facts about me . . .</h1>
			<section className='about-grid'>
				<div className='about-image'>
					<img
						src={photo}
						alt='Siva Nare'
						className='Potrait'
					/>
				</div>
				<div className='about-content'>
					<p>Hey there! I'm Siva.</p>
					<p>
						I work out of Stockholm, Sweden, as a full-stack software developer who's deeply passionate about
						programming. My expertise spans <span>JavaScript, React, Next.js, Vue, C# .NET, HMTL & CSS.</span> My
						approach to crafting applications involves prioritizing clean, readable code and effective problem-solving.
					</p>
					<p>
						Building a successful app involves numerous factors, which is why I value having a broad understanding of
						what it takes to create a compelling website.
					</p>
					<p>
						Outside of work, I find joy in cooking, with chicken biryani being my ultimate favorite dish. Exploring new
						cities or national parks is another passion of mine, always on the lookout for the best weather and food
						experiences.
					</p>
					<p> Feel free to check out my LinkedIn for more details! </p>
				</div>
			</section>
			<div className='connect'>
				<a
					href='https://www.linkedin.com/in/sivanare/'
					target='_blank'
					rel='noopener noreferrer'
				>
					Connect with me
				</a>
			</div>
		</div>
	)
}
