import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Navbar.css';
import logo from '../assets/images/logo.png';
import { FaGithub } from 'react-icons/fa';
import { RiMenuFill, RiCloseFill } from 'react-icons/ri';

export default function Navbar() {
	const [click, setClick] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [button, setButton] = useState(true);
	const [navbar, setNavbar] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	useEffect(() => {
		showButton();
	}, []);

	window.addEventListener('resize', showButton);

	const changeBackground = () => {
		if (window.scrollY >= 95) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};
	window.addEventListener('scroll', changeBackground);

	function scrollToTop() {
		scroll.scrollToTop();
	}

	function toTopAndCloseMobileMenu() {
		scrollToTop();
		closeMobileMenu();
	}

	return (
		<nav className={navbar ? 'navbar active' : 'navbar'} id='navbar'>
			<div className='navbar-container'>
				<div
					to='Home'
					className='navbar-logo'
					onClick={toTopAndCloseMobileMenu}>
					<img src={logo} alt='Siva Nare logo' className='logo-png' />
					Siva <span>Nare</span>
				</div>

				<div className='menu-icon' onClick={handleClick}>
					{click ? (
						<RiCloseFill className='menu-icon-close' />
					) : (
						<RiMenuFill className='menu-icon-bars' />
					)}
				</div>

				<ul className={click ? 'nav-menu active' : 'nav-menu'}>
					<li className='nav-item'>
						<Link
							to='Home'
							className='nav-links'
							onClick={closeMobileMenu}
							activeClass='active'
							spy={true}
							smooth={true}
							offset={0}
							duration={500}>
							HOME
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							to='AboutMe'
							className='nav-links'
							onClick={closeMobileMenu}
							activeClass='active'
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}>
							ABOUT ME
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							to='ContactMe'
							className='nav-links'
							onClick={closeMobileMenu}
							activeClass='active'
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}>
							CONTACT
						</Link>
					</li>
					<li className=' nav-item github-icon'>
						<a
							href='https://github.com/sivanare'
							target='_blank'
							aria-label='Github'
							rel='noopener noreferrer'
							className='nav-links'
							onClick={closeMobileMenu}>
							<FaGithub className='nav-github' />
						</a>
					</li>
				</ul>
			</div>
		</nav>
	);
}
