import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function ContactMe() {
	const [fieldErrors, setFieldErrors] = useState({});
	const validationRules = {
		firstName: (val) => !!val,
		lastName: (val) => !!val,
		email: (val) => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
		phoneNumber: (val) => !!val,
		message: (val) => !!val,
	};
	const validate = () => {
		let errors = {};
		let hasErrors = false;
		for (let key of Object.keys(inputs)) {
			errors[key] = !validationRules[key](inputs[key]);
			hasErrors |= errors[key];
		}
		setFieldErrors((prev) => ({ ...prev, ...errors }));
		return !hasErrors;
	};
	const renderFirstNameFieldError = (field) => {
		if (fieldErrors[field]) {
			return <p className='errorMsg'>First Name is required.</p>;
		}
	};
	const renderLastNameFieldError = (field) => {
		if (fieldErrors[field]) {
			return <p className='errorMsg'>Last Name is required.</p>;
		}
	};
	const renderEmailFieldError = (field) => {
		if (fieldErrors[field]) {
			return <p className='errorMsg'>Valid email id is required.</p>;
		}
	};
	const renderPhoneFieldError = (field) => {
		if (fieldErrors[field]) {
			return <p className='errorMsg'>Phone number is required.</p>;
		}
	};
	const renderMessageFieldError = (field) => {
		if (fieldErrors[field]) {
			return <p className='errorMsg'>Valid message is required.</p>;
		}
	};

	/* End new validation ^^^^ */

	// Input Change Handling
	const [inputs, setInputs] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		message: '',
	});
	const handleOnChange = (event) => {
		event.persist();
		setInputs((prev) => ({
			...prev,
			[event.target.id]: event.target.value,
		}));
	};

	// Server State Handling
	const [serverState, setServerState] = useState({
		submitting: false,
		status: null,
	});
	const handleServerResponse = (ok, msg) => {
		setServerState({
			submitting: false,
			status: { ok, msg },
		});
		if (ok) {
			setFieldErrors({});
			setInputs({
				firstName: '',
				lastName: '',
				email: '',
				phoneNumber: '',
				message: '',
			});
		}
	};
	const handleOnSubmit = (event) => {
		event.preventDefault();
		if (!validate()) {
			return;
		}
		setServerState({ submitting: true });
		axios({
			method: 'POST',
			url: 'https://formspree.io/xnqoqjwp',
			data: inputs,
		})
			.then((r) => {
				handleServerResponse(true, 'Thanks! I have received your Message.');
			})
			.catch((r) => {
				handleServerResponse(false, r.response.data.error);
			});
	};

	useEffect(() => {
		// Only perform interactive validation after submit
		if (Object.keys(fieldErrors).length > 0) {
			validate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	return (
		<div className='ContactMe'>
			<h1>Get in touch with me</h1>
			<section className='contact-page'>
				<form onSubmit={handleOnSubmit} noValidate>
					<div className='form-group'>
						<div className='input-div'>
							{renderFirstNameFieldError('firstName')}
							<input
								id='firstName'
								type='text'
								name='firstName'
								required
								onChange={handleOnChange}
								value={inputs.firstName}
								className={fieldErrors.firstName ? 'error' : ''}
							/>
							<label htmlFor='firstName' className='label-name'>
								<span className='content-name'>First Name</span>
							</label>
						</div>
						<div className='input-div'>
							{renderLastNameFieldError('lastName')}
							<input
								id='lastName'
								type='text'
								name='lastName'
								required
								onChange={handleOnChange}
								value={inputs.lastName}
								className={fieldErrors.lastName ? 'error' : ''}
							/>
							<label htmlFor='lastName' className='label-name'>
								<span className='content-name'>Last Name</span>
							</label>
						</div>
						<div className='input-div'>
							{renderEmailFieldError('email')}
							<input
								id='email'
								type='email'
								name='email'
								required
								onChange={handleOnChange}
								value={inputs.email}
								className={fieldErrors.email ? 'error' : ''}
							/>
							<label htmlFor='email' className='label-name'>
								<span className='content-name'>Email</span>
							</label>
						</div>
						<div className='input-div'>
							{renderPhoneFieldError('phoneNumber')}
							<input
								id='phoneNumber'
								type='tel'
								name='phoneNumber'
								required
								onChange={handleOnChange}
								value={inputs.phoneNumber}
								className={fieldErrors.phoneNumber ? 'error' : ''}
							/>
							<label htmlFor='phoneNumber' className='label-name'>
								<span className='content-name'>Phone</span>
							</label>
						</div>

						<div className='input-div'>
							{renderMessageFieldError('message')}
							<textarea
								id='message'
								name='message'
								required
								rows='4'
								onChange={handleOnChange}
								value={inputs.message}
								className={fieldErrors.message ? 'error' : ''}
							/>

							<label htmlFor='message' className='label-name'>
								<span className='text-area-content-name'>Message</span>
							</label>
						</div>
						<div className='submit-btn'>
							<button type='submit' disabled={serverState.submitting}>
								Submit
							</button>
							<div className='submit-message'>
								{serverState.status && (
									<p className={!serverState.status.ok ? 'errorMsg' : ''}>
										{serverState.status.msg}
									</p>
								)}
							</div>
						</div>
					</div>
				</form>
			</section>
		</div>
	);
}
