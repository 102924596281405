import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

export default function Home() {
	return (
		<div className='Home'>
			<HeroSection />
		</div>
	);
}
