import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import video from '../assets/videos/video-1.mp4';
import videoImg from '../assets/videos/SivaNare_video_alt_img.jpeg';

function HeroSection() {
	return (
		<div className='hero-container'>
			<video src={video} autoPlay loop muted poster={videoImg} />
			<h1>Developer You Can Trust</h1>
			<p>Lets build something epic</p>
			<div className='hero-btns'>
				<Button
					className='btns'
					buttonStyle='btn--outline'
					buttonSize='btn--large'
					path='/ContactMe'>
					GET STARTED
				</Button>
			</div>
		</div>
	);
}

export default HeroSection;
