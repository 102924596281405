import React from 'react';
import './App.css';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import AboutMe from './components/pages/AboutMe';
import ContactMe from './components/pages/ContactMe';
import Footer from './components/Footer';

function App() {
	return (
		<div className='App'>
			<Navbar />
			<Home />
			<AboutMe />
			<ContactMe />
			<Footer />
		</div>
	);
}

export default App;
