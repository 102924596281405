import React, { useState, useEffect } from 'react';
import './Footer.css';

function Footer() {
	const [date, setDate] = useState();

	const getYear = () => setDate(new Date().getFullYear());

	useEffect(() => {
		getYear();
	}, []);
	return (
		<div className='footer-container'>
			<p>
				Email: <a href='mailto:hello@sivanare.com'>hello@sivanare.com</a>
			</p>
			<p>
				Phone: <a href='tel:+46739575966'>+46 739 575 966</a>
			</p>
			<div className='line'></div>
			<div className='footer-bottom'>
				<span> &copy; {date}</span>
			</div>
		</div>
	);
}

export default Footer;
